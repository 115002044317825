import $ from "jquery";
import _ from "underscore";

const onEnter = () => {
  const triggerElm = document.getElementsByClassName("js-pageTop");
  const trigger = triggerElm[triggerElm.length - 1];
  const footerElm = document.getElementsByClassName("js-footer");
  const footer = footerElm[footerElm.length - 1];

  if (!trigger) { return false; }
  if (!footer) { return false; }

  const innerElm = trigger.getElementsByTagName("a")[0];
  $(innerElm).on("click", function(e) {
    e.preventDefault();
    if ($(this).hasClass("-show")) {
      $("body, html").animate({ scrollTop: 0 }, 500);
    }
  });

  let rect = footer.getBoundingClientRect();
  let leftPos = rect.left + footer.clientWidth - trigger.clientWidth;
  let fixedFlg = false;
  let timeout;

  // 位置を設定
  const setPosition = () => {
    let y = window.pageYOffset + window.innerHeight;
    let scrollY = window.pageYOffset;

    if (
      scrollY < parseInt(window.innerHeight / 3) &&
      y < footer.offsetTop
    ) {
      innerElm.classList.remove("-show");
    } else if (y > footer.offsetTop) {
      innerElm.classList.add("-show");
      trigger.classList.remove("-fixed");
      trigger.style.left = "";
      fixedFlg = false;
    } else {
      innerElm.classList.add("-show");
      trigger.classList.add("-fixed");
      trigger.style.left = leftPos + "px";
      fixedFlg = true;
    }
  }

  const scroll = () => {
    if (timeout) {
      cancelAnimationFrame(timeout);
    }
    timeout = requestAnimationFrame(() => {
      setPosition();
    });
  }
  $(document).on("scroll.pageTop", scroll);

  // スクロール終了後にステータスバーの高さが取れない場合があるので
  // スクロールが終わったら一度だけ位置を調整
  let timer = false;
  const scrollEnd = () => {
    if( timer !== false ){
      clearTimeout( timer );
    }
    timer = setTimeout( function(){
      setPosition();
    }, 600);
  }
  $(document).on("scroll.pageTopEnd", scrollEnd);

  const resize = () => {
    rect = footer.getBoundingClientRect();
    leftPos = rect.left + footer.clientWidth - trigger.clientWidth;
    if (fixedFlg) {
      trigger.style.left = leftPos + "px";
    }
  }
  const debounceOnResize = _.debounce(() => {
    resize();
  }, 100);
  $(window).on("resize.pageTop", debounceOnResize);

  scroll();
}

const onLeave = () => {
  // イベント削除
  $(document).off("scroll.pageTop");
  $(document).off("scroll.pageTopEnd");
  $(window).off("resize.pageTop");
};

export { onEnter, onLeave };
