/*
 * get()
 * 現在の言語を返却 jp, en
 */

export default class getLang {
  constructor() {
    this.header = document.getElementsByClassName("js-header")[0];
    this.langElm = this.header.getElementsByClassName("language")[0].getElementsByTagName("a");
    this.langElmNode = Array.prototype.slice.call(this.langElm, 0);
    this.lang = "ja";
  }

  get() {
    this.langElmNode.forEach(elm => {
      if (elm.classList.contains("is-active")) {
        this.lang = elm.dataset.lang;
      }
    });
    return this.lang;
  }
}
