import $ from "jquery";
import _ from "underscore";
import setFooterPosition from "../lib/SetFooterPosition";

const onEnter = () => {
  const footerElm = document.getElementsByClassName("js-footer");
  const footer = footerElm[footerElm.length - 1];
  const contentsElm = document.getElementsByClassName("contents");
  const contents = contentsElm[contentsElm.length - 1];
  const pageTopElm = document.getElementsByClassName("js-pageTop");
  const pageTop = pageTopElm[pageTopElm.length - 1];

  const libFooterPos = new setFooterPosition();

  if (!footer) { return false; }
  if (!contents) { return false; }

  const childElm = contents.children;
  const childElmNode = Array.prototype.slice.call(childElm, 0);

  // スクロールしないページではフッターが下固定になるようにmarginTopを調整
  const setFooterPos = () => {
    libFooterPos.set();
  }

  const debounceOnResize = _.debounce(() => {
    setFooterPos();
  }, 100);
  $(window).on("resize.footer", debounceOnResize);

  if (pageTop) {
    $(pageTop).show();
  }
  setFooterPos();
}

const onLeave = () => {
  // イベント削除
  $(window).off("resize.footer");
}

export { onEnter, onLeave };

