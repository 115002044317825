const status = {};

const handleView = e => {
  const id = e.target.dataset.viewBtn;
  const item = document.querySelectorAll(`[data-view-item="${id}"]`);
  if (status[id]) {
    item.forEach(el => {
      el.classList.add("is-hidden");
    });
    e.target.textContent = "▼ View More";
    status[id] = false;
  } else {
    item.forEach(el => {
      el.classList.remove("is-hidden");
    });
    e.target.textContent = "▲ Close";
    status[id] = true;
  }
};

const onEnter = () => {
  const target = document.querySelectorAll("[data-view-btn]");
  target.forEach(el => {
    const id = el.dataset.viewBtn;
    status[id] = false;
    el.textContent = "▼ View More";
    el.addEventListener("click", handleView);
  });
};

const onLeave = () => {
  const target = document.querySelectorAll("[data-view-btn]");
  target.forEach(el => {
    const id = el.dataset.viewBtn;
    status[id] = false;
    el.removeEventListener("click", handleView);
  });
};

export { onEnter, onLeave };
