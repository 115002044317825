import $ from "jquery";
import _ from "underscore";

const onEnter = () => {
  const triggerElm = document.getElementsByClassName("js-pageBack");
  const trigger = triggerElm[triggerElm.length - 1];
  const footerElm = document.getElementsByClassName("js-footer");
  const footer = footerElm[footerElm.length - 1];

  if (!trigger) { return false; }
  if (!footer) { return false; }

  // トップページでは非表示
  if (location.pathname == "/") {
    $(trigger).hide();
    return false;
  }

  $(trigger).find("a").on("click", function(e) {
    e.preventDefault();
    history.back();
  });

  let rect = footer.getBoundingClientRect();
  let leftPos = rect.left;
  let fixedFlg = false;
  let timeout;

  // 位置を設定
  const setPosition = () => {
    let scroll_top = $(this).scrollTop();  //. スクロール位置
    let y = window.pageYOffset + window.innerHeight;

    if (footer.classList.contains("-bottom")) {
      trigger.classList.remove("-fixed");
      trigger.style.left = "";
      return false;
    }

    if (y > footer.offsetTop) {
      trigger.classList.remove("-fixed");
      trigger.style.left = "";
      fixedFlg = false;
    } else {
      trigger.classList.add("-fixed");
      trigger.style.left = leftPos + "px";
      fixedFlg = true;
    }
  }

  const scroll = () => {
    if (timeout) {
      cancelAnimationFrame(timeout);
    }
    timeout = requestAnimationFrame(() => {
      setPosition();
    });
  }
  $(document).on("scroll.pageBack", scroll);

  // スクロール終了後にステータスバーの高さが取れない場合があるので
  // スクロールが終わったら一度だけ位置を調整
  let timer = false;
  const scrollEnd = () => {
    if( timer !== false ){
      clearTimeout( timer );
    }
    timer = setTimeout( function(){
      setPosition();
    }, 600);
  }
  $(document).on("scroll.pageBackEnd", scrollEnd);

  const resize = () => {
    rect = footer.getBoundingClientRect();
    leftPos = rect.left;
    if (fixedFlg) {
      trigger.style.left = leftPos + "px";
    }
  }
  const debounceOnResize = _.debounce(() => {
    resize();
  }, 100);
  $(window).on("resize.pageBack", debounceOnResize);

  scroll();
}

const onLeave = () => {
  // イベント削除
  $(document).off("scroll.pageBack");
  $(document).off("scroll.pageBackEnd");
  $(window).off("resize.pageBack");
};

export { onEnter, onLeave };
