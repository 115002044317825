export default function Header() {
  const btn = document.querySelector("[data-header-btn]");
  const header = document.querySelector("[data-header]");
  btn.addEventListener("click", () => {
    header.classList.toggle("is-active");
    if (header.classList.contains("is-active")) {
      header.classList.add("activate");
    } else {
      setTimeout(
        function(){
          header.classList.remove("activate");
        }
      ,300);
    }
  });
}
