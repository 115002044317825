import $ from "jquery";
import _ from "underscore";
import setFooterPosition from "../lib/SetFooterPosition";

let libFooterPos;
let sortFlg = false;

// 絞り込み
function refineList(elm, sortList) {
  localStorage.setItem("filteredWord", "");
  localStorage.setItem("filteredId", elm.attr("data-filter").split(","));
  localStorage.setItem("filteredCat", elm.attr("data-category").split(","));
  console.log("refineList");
  $(".js-searchTrigger")
    .removeClass("is-active")
    .next()
    .stop(false, false)
    .slideUp(300);
  var filter = elm.attr("data-filter").split(",");
  var item = [];

  for (var x = 0, filterLen = filter.length; x < filterLen; x++) {
    var index = filter[x];
    var targetList = sortList[index];
    for (var i = 0, len = targetList.length; i < len; i++) {
      item.push($(".js-list").find("[data-id='" + targetList[i] + "']"));
    }
  }

  hideList();
  hideHits();

  if (elm.closest(".searchList").hasClass("js-searchSyllabaryContent")) {
    sort();
  } else {
    if (sortFlg) {
      resetSort();
    }
  }

  _.delay(() => {
    showList(item);
    showHits(elm);
    $('.p-lawyer-list-wrap').addClass('active');
    console.log('ADDDS');
  }, 200);
}

// キーワード検索
function keyword() {
  localStorage.setItem("filteredCat", "keyword");
  let currentSearchTxt;
  var searchTxt = $(this)
    .val()
    .replace(/\s+/g, "");

  if (searchTxt == currentSearchTxt) {
    return false;
  }
  currentSearchTxt = searchTxt;

  //文字が入力されている場合
  if (searchTxt != "") {
    hideList();
    $(".js-list")
      .find("li")
      .each(function() {
        var targetTxt = $(this)
          .find("[data-keywords]")
          .text();

        // 文字列が存在するか
        if (targetTxt.indexOf(searchTxt) != -1) {
          $(this)
            .addClass("is-current")
            .removeClass("-hide");
        } else {
          $(this)
            .removeClass("is-current")
            .addClass("-hide");
        }
      });

    if (sortFlg) {
      resetSort();
    }

    showHits("keyword");

    _.delay(() => {
      localStorage.setItem("filteredWord", searchTxt);
      $(".js-footer").removeClass("-hide");
      libFooterPos.set();
    }, 400);
  } else {
    // inputが空の時はリセット
    reset();
    currentSearchTxt = "";
  }
}

const onEnter = () => {
  libFooterPos = new setFooterPosition();

  // アコーディオン
  $(".js-searchTrigger").on("click", function() {
    //英語の場合 文字が多いので該当者のいない絞り込み非表示
    if (
      $(this)
        .next()
        .hasClass("js-sort-en") &&
      !$(".js-sort-en").hasClass("is-init")
    ) {
      if (!(typeof search_syllabary_item_en === "undefined")) {
        _.each(search_syllabary_item_en, function(elm, i) {
          if (!elm.length) {
            $(".js-sort-en")
              .find("ul")
              .find("li")
              .eq(i - 1)
              .hide();
          }
        });
        $(".js-sort-en").addClass("is-init");
      }
    }
    const siblingsTrigger = $(this)
      .parent()
      .siblings(".searchBox")
      .find(".js-searchTrigger");

    // 選択外のアコーディオンを閉じる
    siblingsTrigger.each(function() {
      if ($(this).hasClass("is-active")) {
        closeMenu($(this), false);
      }
    });

    if (!$(this).hasClass("is-active")) {
      // 選択したアコーディオンを開く
      openMenu($(this));
    } else {
      // 選択したアコーディオンを閉じる
      closeMenu($(this), true);
    }
  });

  $(document).on("click.search touchend.search", function(e) {
    if (!$(e.target).closest(".searchList,.js-searchTrigger").length) {
      $(".js-searchTrigger").each(function() {
        if ($(this).hasClass("is-active")) {
          closeMenu($(this), true);
        }
      });
    }
  });

  // 業務分野で探す
  $(".js-searchFieldContent li").on("click", function() {
    if (!(typeof search_field_item === "undefined")) {
      refineList($(this), search_field_item);
    }
  });

  // 役職で探す
  $(".js-searchPositionContent li").on("click", function() {
    if (!(typeof search_position_item === "undefined")) {
      refineList($(this), search_position_item);
    }
  });

  // 勤務地で探す
  $(".js-searchOfficeContent li").on("click", function() {
    if (!(typeof search_office_item === "undefined")) {
      refineList($(this), search_office_item);
    }
  });

  // 50音順で探す
  $(".js-searchSyllabaryContent li").on("click", function() {
    if ($("html").attr("lang") == "ja") {
      if (!(typeof search_syllabary_item === "undefined")) {
        refineList($(this), search_syllabary_item);
      }
    } else {
      // 50音順で探す 英語
      if (!(typeof search_syllabary_item_en === "undefined")) {
        refineList($(this), search_syllabary_item_en);
      }
    }
  });

  // 条件をクリア
  $(".js-resetBtn").on("click", function() {
    reset();
  });

  $(".js-input").on("input", keyword);
};

// 一覧の順番リセット
const resetSort = () => {
  sortFlg = false;
  if (!(typeof search_lawyers_default === "undefined")) {
    const list = search_lawyers_default;
    Object.keys(list).forEach(key => {
      let targetElm = $(".js-list").find("[data-id='" + list[key] + "']");
      $(".js-list").append(targetElm);
    });
  }
};

// 一覧の順番ソート
const sort = () => {
  sortFlg = true;
  if ($("html").attr("lang") == "ja") {
    if (!(typeof search_lawyers_sort === "undefined")) {
      const list = search_lawyers_sort;
      if (list) {
        Object.keys(list).forEach(key => {
          let targetElm = $(".js-list").find("[data-id='" + list[key] + "']");
          $(".js-list").append(targetElm);
        });
      }
    }
  } else {
    if (!(typeof search_lawyers_sort_en === "undefined")) {
      const list = search_lawyers_sort_en;
      if (list) {
        Object.keys(list).forEach(key => {
          let targetElm = $(".js-list").find("[data-id='" + list[key] + "']");
          $(".js-list").append(targetElm);
        });
      }
    }
  }
};

// 一覧の並び替え

// アコーディオンOPEN
const openMenu = elm => {
  elm.addClass("is-active");
  elm.next(".searchList").slideDown(300);

  if (window.innerWidth > 750) {
    $("main").css({
      position: "relative",
      "z-index": 1000
    });
  } else {
    $("main").css({
      position: "relative",
      "z-index": ""
    });
  }
};

// アコーディオンCLOSE
const closeMenu = (elm, flg) => {
  elm.removeClass("is-active");
  elm.next(".searchList").slideUp(300);

  // 全て閉じる場合
  if (flg) {
    _.delay(() => {
      $("main").css({
        position: "relative",
        "z-index": ""
      });
    }, 300);
  }
};

// 検索結果リストを全て非表示
const hideList = () => {
  $(".js-list")
    .find("li")
    .removeClass("is-current")
    .addClass("-hide");

  $(".js-footer").addClass("-hide");
};

// 対象の検索結果リストを表示
const showList = item => {
  for (var i = 0, len = item.length; i < len; i++) {
    item[i].addClass("is-current").removeClass("-hide");
  }
  // $(".val-" + item)
  // .addClass("is-current")
  // .removeClass("-hide");

  // フッター位置調整
  _.delay(() => {
    libFooterPos.set();
    $(".js-footer").removeClass("-hide");
  }, 200);
};

// 検索件数非表示
const hideHits = () => {
  $(".js-result")
    .removeClass("-show")
    .addClass("-hide");
};

// 検索件数表示
const showHits = elm => {
  var current = $(".js-list").find(".is-current").length;

  $(".js-result").removeClass("is-zero");
  $(".js-resultNum").html(current);
  // 検索条件表示
  let condText = "";
  if (elm == "keyword") {
    condText = $(".js-input").val();
  } else {
    condText = elm.text();
  }
  $(".js-result-cond").text(condText);

  if (current == 0) {
    $(".js-result").addClass("is-zero");
  }

  $(".js-result")
    .addClass("-show")
    .removeClass("-hide");
};

const reset = () => {
  localStorage.setItem("filteredId", "");
  localStorage.setItem("filteredCat", "");
  localStorage.setItem("filteredWord", "");
  $(".js-list")
    .find("li")
    .removeClass("is-current")
    .removeClass("-hide");
  $(".js-resultNum").html("");
  $(".js-input").val("");

  hideHits();

  if (sortFlg) {
    resetSort();
  }

  _.delay(() => {
    libFooterPos.set();
  }, 400);
};

const onEnterCompleted = () => {
  const id = localStorage.getItem("filteredId");
  const cat = localStorage.getItem("filteredCat");
  const word = localStorage.getItem("filteredWord");
  var sorted = word || id  ? true : false;
  if (sorted) {
    $('.p-lawyer-list-wrap').removeClass('active');
    console.log('removes');
  } else {
    $('.p-lawyer-list-wrap').addClass('active');
  }
  
  if (word) {
    $(".js-input").val(word);
    hideList();
    $(".js-list")
      .find("li")
      .each(function() {
        var targetTxt = $(this)
          .find("[data-keywords]")
          .text();

        // 文字列が存在するか
        if (targetTxt.indexOf(word.replace(/\s+/g, "")) != -1) {
          $(this)
            .addClass("is-current")
            .removeClass("-hide");
        } else {
          $(this)
            .removeClass("is-current")
            .addClass("-hide");
        }
      });

    if (sortFlg) {
      resetSort();
    }

    showHits("keyword");

    _.delay(() => {
      localStorage.setItem("filteredWord", word);
      $(".js-footer").removeClass("-hide");
      libFooterPos.set();
      $('.p-lawyer-list-wrap').addClass('active');
    }, 400);
    return;
  }
  if (id) {
    const target = $(`[data-filter="${id}"]`);
    if (cat === "practices") {
      if (!(typeof search_field_item === "undefined")) {
        refineList(target, search_field_item);
      } else {
        $('.p-lawyer-list-wrap').addClass('active');
      }
      return;
    }
    if (cat === "position") {
      if (!(typeof search_position_item === "undefined")) {
        refineList(target, search_position_item);
      } else {
        $('.p-lawyer-list-wrap').addClass('active');
      }
      return;
    }
    if (cat === "office") {
      if (!(typeof search_office_item === "undefined")) {
        refineList(target, search_office_item);
      } else {
        $('.p-lawyer-list-wrap').addClass('active');
      }
      return;
    }
    if (cat === "syllabary") {
      if (!(typeof search_syllabary_item === "undefined")) {
        refineList(target, search_syllabary_item);
      } else {
        $('.p-lawyer-list-wrap').addClass('active');
      }
      return;
    }
    if (cat === "syllabary_en") {
      if (!(typeof search_syllabary_item_en === "undefined")) {
        refineList(target, search_syllabary_item_en);
      } else {
        $('.p-lawyer-list-wrap').addClass('active');
      }
      return;
    }
  }
};

const onLeave = () => {
  // イベント削除
  $('.p-lawyer-list-wrap').removeClass('active');
  $(document).off("click.search touchend.search");
};

export { onEnter, onEnterCompleted, onLeave };
