import $ from "jquery";

/*
 * set()
 * コンテンツ < ウィンドウ高さ の場合にフッターのマージントップを調整
 */

export default class setFooterPosition {
  constructor() {
    this.headerElm = document.getElementsByClassName("js-header");
    this.header = this.headerElm[this.headerElm.length - 1];
    this.footerElm = document.getElementsByClassName("js-footer");
    this.footer = this.footerElm[this.footerElm.length - 1];
    this.contentsElm = document.getElementsByClassName("contents");
    this.contents = this.contentsElm[this.contentsElm.length - 1];
    this.pageTopElm = document.getElementsByClassName("js-pageTop");
    this.pageTop = this.pageTopElm[this.pageTopElm.length - 1];
    this.pageBackElm = document.getElementsByClassName("js-pageBack");
    this.pageBack = this.pageBackElm[this.pageBackElm.length - 1];
    this.childElm = this.contents.children;
    this.childElmNode = Array.prototype.slice.call(this.childElm, 0);
  }

  init() {}

  set() {
    let height = 0;
    let headerHeight = 0;
    let headerStaticFlg = false;
    this.footer.style.marginTop = "";
    let footerMarginTop = parseInt(window.getComputedStyle(this.footer, null).getPropertyValue("margin-top"));

    this.childElmNode.forEach( elm => {
      height += elm.clientHeight;

      // 上下マージン追加
      height += parseInt(window.getComputedStyle(elm, null).getPropertyValue("margin-top"));
      height += parseInt(window.getComputedStyle(elm, null).getPropertyValue("margin-bottom"));
    });

    if (this.header) {
      if (window.getComputedStyle(this.header, null).getPropertyValue("position") == "static") {
        headerStaticFlg = true;
        headerHeight += this.header.clientHeight;

        // 上下マージン追加
        headerHeight += parseInt(window.getComputedStyle(this.header, null).getPropertyValue("margin-top"));
        headerHeight += parseInt(window.getComputedStyle(this.header, null).getPropertyValue("margin-bottom"));

        this.contents.style.minHeight = "0px";
      } else {
        this.contents.style.minHeight = "";
      }
    }

    if (headerStaticFlg && height + headerHeight < window.innerHeight) {
      this.footer.style.marginTop = footerMarginTop + window.innerHeight - height - headerHeight + "px";
      this.footer.classList.add("-bottom");
      if (this.pageTop) {
        $(this.pageTop).hide();
      }
      if (this.pageBack) {
        this.pageBack.classList.remove("-fixed");
        this.pageBack.style.left = "";
      }
    } else if (!headerStaticFlg && height < window.innerHeight) {
      this.footer.style.marginTop = footerMarginTop + window.innerHeight - height + "px";
      this.footer.classList.add("-bottom");
      if (this.pageTop) {
        $(this.pageTop).hide();
      }
      if (this.pageBack) {
        this.pageBack.classList.remove("-fixed");
        this.pageBack.style.left = "";
      }
    } else {
      this.footer.style.marginTop = "";
      this.footer.classList.remove("-bottom");
      if (this.pageTop) {
        $(this.pageTop).show();
      }
    }
  }
}
