import $ from "jquery";

// 弁護士等、トピックス一覧 ページで使用するソート用配列を出力
export default class Sort {
  constructor() {
    this.init();
  }

  init() {
    this.sortLawyer();
  }

  // 弁護士一覧の絞り込み用js配列を出力
  sortLawyer() {
    const url = "/common/includes/lawyers/sortList/";
    this.ajax(url).done(data => {
      $("body").append(data);
    });
  }

  ajax(url) {
    return $.ajax({
      url: url,
      type: "GET",
      dataType: "text"
    });
  }
}
