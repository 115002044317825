import $ from "jquery";
import Barba from "barba.js";

$(() => {
  // 同一ページのリンクを無効
  const links = document.querySelectorAll("a[href]");
  const linkNode = Array.prototype.slice.call(links, 0);
  let defaultHref;
  linkNode.forEach(elm => {
    elm.addEventListener("click", e => {
      if (e.currentTarget.href === window.location.href) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  });

  // 新しいページが準備できたときの処理
  Barba.Dispatcher.on(
    "newPageReady",
    (currentStatus, oldStatus, container, newPageRawHTML) => {

      if (window.location.href.indexOf('topics_category') !== -1 && window.location.href.indexOf('?') !== -1) {
        // e.preventDefault();
        if (localStorage.getItem("topicsReload") === window.location.href.split('?')[1]) {
          localStorage.setItem("topicsReload", '');
          Barba.Pjax.goTo(window.location.href.split('?')[0].split(window.location.host)[1]);
          
        }
      }
      localStorage.setItem("topicsReload", '');

      // Google Analytics更新
      if (
        typeof ga === "function" &&
        Barba.HistoryManager.history.length >= 1
      ) {
        ga("send", "pageview", location.pathname);
      }

      // gtag更新
      if (
        typeof gtag === "function" &&
        Barba.HistoryManager.history.length >= 1
      ) {
        gtag("config", "UA-131352527-1", { page_path: location.pathname });
      }

      history.scrollRestoration = "manual";
      if (Barba.HistoryManager.history.length === 1) {
        defaultHref = window.location.href;
        return;
      }

      //lang 属性更新
      const langRe = /<html[\s\S.]lang="([ja|en]+)">/g;
      const langMatchArr = langRe.exec(newPageRawHTML);
      if (Array.isArray(langMatchArr)) {
        document
          .getElementsByTagName("html")[0]
          .setAttribute("lang", langMatchArr[1]);
      }

      // metaタグ更新
      const head = document.head;
      const newPageRawHead = newPageRawHTML.match(
        /<head[^>]*>([\s\S.]*)<\/head>/i
      )[0];
      const newPageHead = document.createElement("head");
      newPageHead.innerHTML = newPageRawHead;

      // 更新するタグ
      const removeHeadTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='og']"
      ].join(",");
      const headTags = head.querySelectorAll(removeHeadTags);
      for (let i = 0, len = headTags.length; i < len; i++) {
        head.removeChild(headTags[i]);
      }
      const newHeadTags = newPageHead.querySelectorAll(removeHeadTags);

      for (let i = 0, len = newHeadTags.length; i < len; i++) {
        head.appendChild(newHeadTags[i]);
      }

      if (location.pathname.indexOf("/lawyers/") === -1 ){
        // console.log("弁護士以外");
        localStorage.setItem("filteredId", "");
        localStorage.setItem("filteredCat", "");
        localStorage.setItem("filteredWord", "");
      }


      // console.log(location.pathname);

    }
  );
  

  window.addEventListener('beforeunload', (e) => {

    // localStorage.setItem("filteredId", "");
    // localStorage.setItem("filteredCat", "");
    // localStorage.setItem("filteredWord", "");

    if (window.location.href.indexOf('topics_category') !== -1 && window.location.href.indexOf('?') !== -1) {
      // e.preventDefault();
      // Barba.Pjax.goTo(null,null,window.location.href.split('?')[0].split(window.location.host)[1]);
      localStorage.setItem("topicsReload", window.location.href.split('?')[1]);
    }

  });

  // ページ遷移トランジション
  const FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      // ローディングが終わるとすぐ古いページをフェードアウトさせて、新しいページをフェードイン
      Promise.all([this.newContainerLoading, this.fadeOut()]).then(
        this.fadeIn.bind(this)
      );
    },

    fadeOut: function() {
      this.oldContainer.style.width = this.oldContainer.clientWidth + "px";
      return $(this.oldContainer)
        .animate(
          {
            opacity: 0,
            marginLeft: "140px"
          },
          { duration: 450, easing: "swing" }
        )
        .promise();
    },

    fadeIn: function() {
      // ページトップに移動（これがないとスクロールしたところのまま画面遷移する）
      $(document).scrollTop(0);


      //document.body.scrollTop = 0;

      var _this = this;

      // newContainerはnewContainerLoadingを解決した後に利用できる
      var $el = $(this.newContainer);
      var width = this.oldContainer.clientWidth;

      // 古いコンテナ
      $(this.oldContainer).hide();

      // 新たに読み込まれるbarba-containerの初期設定
      // visiblityはデフォルトhiddenなのでvisibleに変える
      $el.css({
        visibility: "visible",
        display: "block",
        width: width + "px",
        marginLeft: "-140px",
        opacity: 0
      });

      $el.animate(
        {
          opacity: 1,
          marginLeft: 0
        },
        450,
        function() {
          // 初期ページがお問い合わせページの場合は
          // フォームの状態をクリアするためリロード処理
          if (/\/contact/.test(defaultHref)) {
            const href = window.location.href;
            window.location.href = href;
          }
          // 遷移が終了したら.done()
          // .done()は自動的にDOMから古いコンテナを削除する
          $el.css("width", "");
          _this.done();
        }
      );
    }
  });

  // Barbaに作成した遷移処理を指定
  Barba.Pjax.getTransition = function() {
    return FadeTransition;
  };

  Barba.Prefetch.init();
  Barba.Pjax.start();
});
