import $ from "jquery";
import _ from "underscore";
import getLang from "../lib/getLang";
import setFooterPosition from "../lib/SetFooterPosition";

let libFooterPos;

const onEnter = () => {
  libFooterPos = new setFooterPosition();
  const getLangLib = new getLang();
  const lang = getLangLib.get();

  // アコーディオン
  $(".js-searchTrigger").on("click", function() {
    const siblingsTrigger = $(this)
      .parent()
      .siblings(".searchBox")
      .find(".js-searchTrigger");

    // 選択外のアコーディオンを閉じる
    siblingsTrigger.each(function() {
      if ($(this).hasClass("is-active")) {
        closeMenu($(this), false);
      }
    });

    if (!$(this).hasClass("is-active")) {
      // 選択したアコーディオンを開く
      openMenu($(this));
    } else {
      // 選択したアコーディオンを閉じる
      closeMenu($(this), true);
    }
  });

  $(document).on("click.search touchend.search", function(e) {
    if (!$(e.target).closest(".searchList,.js-searchTrigger").length) {
      $(".js-searchTrigger").each(function() {
        if ($(this).hasClass("is-active")) {
          closeMenu($(this), true);
        }
      });
    }
  });

  // 絞り込み
  function refineList(elm, sortList) {
    $(".js-searchTrigger")
      .removeClass("is-active")
      .next()
      .stop(false, false)
      .slideUp(300);
    var filter = elm.attr("data-filter").split(",");
    var item = [];

    for (var x = 0, filterLen = filter.length; x < filterLen; x++) {
      var index = filter[x];
      var targetList = sortList[index];
      for (var i = 0, len = targetList.length; i < len; i++) {
        item.push($(".js-list").find("[data-id='" + targetList[i] + "']"));
      }
    }

    hideList();
    hideHits();

    _.delay(() => {
      showList(item);
      showHits(elm);
    }, 200);
  }

  // カテゴリで探す
  // 空のカテゴリのli要素を削除
  $(".js-searchCategoryContent")
    .find("li")
    .each((index, elm) => {
      const filter = elm.dataset.filter;
      if (lang == "ja") {
        _.delay(() => {
          if (typeof search_category_item !== "undefined") {
            if (typeof search_category_item[filter] !== "undefined") {
              if (search_category_item[filter].length == 0) {
                $(elm).remove();
              }
            }
          }
        }, 1000);
      } else if (lang == "en") {
      }
    });
  // クリックイベント
  $(".js-searchCategoryContent li").on("click", function() {
    if (lang == "ja") {
      refineList($(this), search_category_item);
    } else if (lang == "en") {
      refineList($(this), search_category_item_en);
    }
  });

  // 50音順で探す
  $(".js-searchAgeContent li").on("click", function() {
    searchAge($(this));
  });

  // 条件をクリア
  $(".js-resetBtn").on("click", function() {
    reset();
    currentSearchTxt = "";
  });

  // 年代別で探す
  function searchAge(elm) {
    $(".js-searchTrigger")
      .removeClass("is-active")
      .next()
      .stop(false, false)
      .slideUp(300);

    let searchTxt = elm.data("filter");
    let item = [];

    $(".js-list")
      .find("li")
      .each(function() {
        let targetTxt = $(this)
          .find(".topicsInfo__date")
          .text();

        // 文字列が存在するリストを取得
        if (targetTxt.indexOf(searchTxt) != -1) {
          let targetId = $(this).data("id");
          item.push($(".js-list").find("[data-id='" + targetId + "']"));
        }
      });

    hideList();
    hideHits();

    _.delay(() => {
      showList(item);
      showHits(elm);
    }, 200);
  }

  // キーワード検索
  let currentSearchTxt;
  function keyword() {
    var searchTxt = $(this).val();

    if (searchTxt == currentSearchTxt) {
      return false;
    }
    currentSearchTxt = searchTxt;

    //文字が入力されている場合
    if (searchTxt !== "") {
      hideList();
      $(".js-list")
        .find("li")
        .each(function() {
          var targetTxt = $(this).text();

          // 文字列が存在するか
          if (targetTxt.indexOf(searchTxt) != -1) {
            $(this)
              .addClass("is-current")
              .removeClass("-hide");
          } else {
            $(this)
              .removeClass("is-current")
              .addClass("-hide");
          }
        });

      showHits("keyword");

      _.delay(() => {
        $(".js-footer").removeClass("-hide");
        libFooterPos.set();
      }, 400);
    } else {
      // inputが空の時はリセット
      reset();
      currentSearchTxt = "";
    }
  }

  $(".js-input").on("keyup", keyword);
};

// アコーディオンOPEN
const openMenu = elm => {
  elm.addClass("is-active");
  elm.next(".searchList").slideDown(300);

  if (window.innerWidth > 750) {
    $("main").css({
      position: "relative",
      "z-index": 1000
    });
  } else {
    $("main").css({
      position: "relative",
      "z-index": ""
    });
  }
};

// アコーディオンCLOSE
const closeMenu = (elm, flg) => {
  elm.removeClass("is-active");
  elm.next(".searchList").slideUp(300);

  // 全て閉じる場合
  if (flg) {
    _.delay(() => {
      $("main").css({
        position: "relative",
        "z-index": ""
      });
    }, 300);
  }
};

// 検索結果リストを全て非表示
const hideList = () => {
  $(".js-list")
    .find("li")
    .removeClass("is-current")
    .addClass("-hide");

  $(".js-footer").addClass("-hide");
};

// 対象の検索結果リストを表示
const showList = item => {
  for (var i = 0, len = item.length; i < len; i++) {
    item[i].addClass("is-current").removeClass("-hide");
  }
  // $(".val-" + item)
  // .addClass("is-current")
  // .removeClass("-hide");

  // フッター位置調整
  _.delay(() => {
    libFooterPos.set();
    $(".js-footer").removeClass("-hide");
  }, 200);
};

// 検索件数非表示
const hideHits = () => {
  $(".js-result")
    .removeClass("-show")
    .addClass("-hide");
};

// 検索件数表示
const showHits = elm => {
  var current = $(".js-list").find(".is-current").length;

  $(".js-result").removeClass("is-zero");
  $(".js-resultNum").html(current);
  // 検索条件表示
  let condText = "";
  if (elm == "keyword") {
    condText = $(".js-input").val();
  } else {
    condText = elm.text();
  }
  $(".js-result-cond").text(condText);

  if (current == 0) {
    $(".js-result").addClass("is-zero");
  }

  $(".js-result")
    .addClass("-show")
    .removeClass("-hide");
};

const reset = () => {
  $(".js-list")
    .find("li")
    .removeClass("is-current")
    .removeClass("-hide");
  $(".js-resultNum").html("");
  $(".js-input").val("");

  hideHits();

  _.delay(() => {
    libFooterPos.set();
  }, 400);
};

$(document).on("click", ".js-anchor", function(e) {
  e.preventDefault();
  console.log("a");

  var targetAnc = $(this).attr("href");
  var pos = $(targetAnc).offset().top;

  $("body, html").animate({ scrollTop: pos }, 500);
});

const onLeave = () => {
  // イベント削除
  $(document).off("click.search touchend.search");
};

export { onEnter, onLeave };
