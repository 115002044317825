/*
 * 個別ページ処理
 * data-namespaceに設定したネームスペース単位でscriptを実行
 * onEnter: ページ表示時
 * onLeave: ページ切替時
 * ページ切替時にイベントが残る場合はイベントをオフにしてメモリリークを避ける
 */

import Barba from "barba.js";
import * as top from "./top";
import * as lawyer from "./lawyer";
import * as lawyerDetail from "./lawyerDetail";
import * as topics from "./topics";
import * as contact from "./contact";
import * as footer from "./footer";
import * as header from "./header";
import * as pageTop from "./pageTop";
import * as pageBack from "./pageBack";
import * as language from "./language";

// top page
const TopIndex = Barba.BaseView.extend({
  namespace: "top",
  onEnter: function() {
    top.onEnter();
  },
  onEnterCompleted: function() {
    top.onEnterCompleted();
  },
  onLeave: function() {
    top.onLeave();
  },
  onLeaveCompleted: function() {}
});
TopIndex.init();

// Lawyer page
const LawyerIndex = Barba.BaseView.extend({
  namespace: "lawyer",
  onEnter: function() {
    lawyer.onEnter();
  },
  onEnterCompleted: function() {
    lawyer.onEnterCompleted();
  },
  onLeave: function() {
    lawyer.onLeave();
  },
  onLeaveCompleted: function() {}
});
LawyerIndex.init();

// lawyerDetail page
const lawyerDetailIndex = Barba.BaseView.extend({
  namespace: "lawyerDetail",
  onEnter: function() {
    lawyerDetail.onEnter();
  },
  onEnterCompleted: function() {},
  onLeave: function() {
    lawyerDetail.onLeave();
  },
  onLeaveCompleted: function() {}
});
lawyerDetailIndex.init();

// Topics page
const TopicsIndex = Barba.BaseView.extend({
  namespace: "topics",
  onEnter: function() {
    topics.onEnter();
  },
  onEnterCompleted: function() {},
  onLeave: function() {
    topics.onLeave();
  },
  onLeaveCompleted: function() {}
});
TopicsIndex.init();

// Contact page
const ContactIndex = Barba.BaseView.extend({
  namespace: "contact",
  onEnter: function() {
    contact.onEnter();
  },
  onEnterCompleted: function() {},
  onLeave: function() {
    contact.onLeave();
  },
  onLeaveCompleted: function() {}
});
ContactIndex.init();

// 全ページで実行
// ページ読込時
Barba.Dispatcher.on(
  "newPageReady",
  (currentStatus, oldStatus, container, newPageRawHTML) => {
    if (Barba.HistoryManager.history.length === 1) {
      // 最初の読み込み時は解除処理はしない
    } else {
      // イベント解除
      footer.onLeave();
      header.onLeave();
      pageTop.onLeave();
      pageBack.onLeave();
      language.onLeave();
    }

    // イベント登録
    footer.onEnter();
    pageTop.onEnter();
    pageBack.onEnter();
    language.onEnter();
  }
);
