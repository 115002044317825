/*
 * 共通処理
 * 最初のページ読み込み時に一度だけ実行
 */

import $ from "jquery";
import Language from "./Language";
import Sort from "./Sort";
import Header from "./Header";

$(() => {
  // 言語切り替え
  new Language();

  // ソート用配列を追加
  new Sort();

  // ヘッダー開閉
  Header();
});

// $(function () {
//     //クッキーポリシー
//     let $cookieModal = $('.js-cookieModal');

//     if(!localStorage.getItem('privacy_accept')) {
//         $('body').addClass('is-fixed');
//         $cookieModal.addClass('is-active');
//     }
//     $('.js-cookieModal-btn, .js-cookieModal-bg').on('click', function(e){
//         e.preventDefault();
//         localStorage.setItem('privacy_accept', 'true');
//         $('body').removeClass('is-fixed');
//         $cookieModal.fadeOut().addClass('is-active');
//     });
// });
