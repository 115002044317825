const status = {
  slide: {
    mv: false,
    topics: false
  }
};

const handleTab = e => {
  const cuurentId = localStorage.getItem("activeTab");
  const cuurentBtn = document.querySelector(`[data-tab-btn="${cuurentId}"]`);
  const cuurentPannel = document.querySelector(`[data-tab-id="${cuurentId}"]`);
  cuurentBtn.classList.remove("is-active");
  cuurentPannel.classList.remove("is-active");
  const newId = e.target.dataset.tabBtn;
  const pannel = document.querySelector(`[data-tab-id="${newId}"]`);
  e.target.classList.add("is-active");
  pannel.classList.add("is-active");
  localStorage.setItem("activeTab", newId);
};

const handleSlider = () => {
  status.slide.mv = new Swiper("[data-mv-slide]", {
    loop: true,
    effect: "fade",
    speed: 2000,
    autoplay: {
      delay: 4000
    },
    pagination: {
      el: "[data-mv-pagination]",
      clickable: true
    }
  });

  status.slide.topics = new Swiper("[data-topics-slide]", {
    loop: true,
    speed: 1500,
    autoplay: {
      delay: 5000
    },
    slidesPerView: "auto",
    spaceBetween: 0,
    pagination: {
      el: "[data-topics-pagination]",
      type: "fraction"
    },
    navigation: {
      nextEl: "[data-topics-next]",
      prevEl: "[data-topics-prev]"
    }
  });
};

const onEnter = () => {
  const tabTraget = document.querySelectorAll("[data-tab-btn]");
  let activeTabId = localStorage.getItem("activeTab");
  if (!activeTabId) {
    localStorage.setItem("activeTab", tabTraget[0].dataset.tabBtn);
    activeTabId = localStorage.getItem("activeTab");
  }
  const btn = document.querySelector(`[data-tab-btn="${activeTabId}"]`);
  const pannel = document.querySelector(`[data-tab-id="${activeTabId}"]`);
  if (btn !== null) {
    btn.classList.add("is-active");
  }
  if (pannel !== null) {
    pannel.classList.add("is-active");
  }
  tabTraget.forEach(el => {
    el.addEventListener("click", handleTab);
  });
};

const onEnterCompleted = () => {
  const tabTraget = document.querySelectorAll("[data-tab-btn]");
  let activeTabId = localStorage.getItem("activeTab");
  if (!activeTabId) {
    localStorage.setItem("activeTab", tabTraget[0].dataset.tabBtn);
    activeTabId = localStorage.getItem("activeTab");
  }
  const btn = document.querySelector(`[data-tab-btn="${activeTabId}"]`);
  const pannel = document.querySelector(`[data-tab-id="${activeTabId}"]`);
  
  if (btn !== null) {
    btn.classList.add("is-active");
  }
  if (pannel !== null) {
    pannel.classList.add("is-active");
  }
  

  handleSlider();
};

const onLeave = () => {
  // イベント削除
  const tabTraget = document.querySelectorAll("[data-tab-btn]");
  tabTraget.forEach(el => {
    el.removeEventListener("click", handleTab);
  });

  status.slide.mv.destroy();
  status.slide.mv = false;
  status.slide.topics.destroy();
  status.slide.topics = false;
};

export { onEnter, onLeave, onEnterCompleted };
