// JP | EN 言語切り替え処理

export default class Language {
  constructor() {
    this.header = document.getElementsByClassName("js-header")[0];
    this.sideNav = document.getElementsByClassName("js-nav")[0];
    this.navList = this.sideNav.getElementsByClassName("js-nav-items");
    this.navListNode = Array.prototype.slice.call(this.navList, 0);
    this.target = this.header
      .getElementsByClassName("language")[0]
      .getElementsByTagName("a");
    this.targetNode = Array.prototype.slice.call(this.target, 0);
    this.langList = ["en"];
    this.init();
  }

  init() {
    this.targetNode.forEach(elm => {
      elm.addEventListener("click", event => {
        if (!elm.classList.contains("is-active")) {
          this.changeLang(elm);
        }
      });
    });
  }

  clear() {
    this.targetNode.forEach(elm => {
      elm.classList.remove("is-active");
    });
  }

  // 言語切り替え処理
  changeLang(elm) {
    // JP | EN 切り替え
    this.clear();
    elm.classList.add("is-active");
    this.lang = elm.dataset.lang;

    // ロゴのリンク差し替え
    const logoLink = this.header.getElementsByClassName("js-logo-link")[0];
    this.changeLink(logoLink);

    const logoImage = this.header.querySelector("[data-logo-image]");
    const logoImageSrc = logoImage.getAttribute("src");
    if (this.lang === "ja") {
      logoImage.setAttribute("src", logoImageSrc.replace("logo_en", "logo"));
    } else {
      logoImage.setAttribute("src", logoImageSrc.replace("logo", "logo_en"));
    }

    // ナビ差し替え
    this.changeNav();
  }

  // ナビ差し替え
  changeNav() {
    this.hideNavAll();
    this.navListNode.forEach(elm => {
      const lang = elm.dataset.lang;
      if (this.lang == lang) {
        this.showNav(elm);
      }
    });
  }

  hideNavAll() {
    this.navListNode.forEach(elm => {
      elm.classList.remove("-show");
      elm.classList.add("-hide");
    });
  }

  showNav(elm) {
    elm.classList.add("-show");
  }

  // リンク差し替え
  changeLink(elm) {
    const baseLink = this.getBaseLink(elm);
    let langPath = "";
    if (this.lang != "ja") {
      langPath = "/" + this.lang;
    }
    const link = langPath + baseLink;
    elm.setAttribute("href", link);
  }

  // 選択言語の値をcookieに保存
  setCookie() {
    // console.log("setCookie");
  }

  // /en などを取り除いたリンクを返却
  getBaseLink(elm) {
    let link = elm.getAttribute("href");

    for (let i = 0, len = this.langList.length; i < len; i++) {
      const pattern = "/" + this.langList[i];
      if (link.indexOf(pattern) === 0) {
        const regExp = new RegExp(pattern, "g");
        link = link.replace(regExp, "");
      }
    }

    return link;
  }
}
