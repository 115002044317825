// 言語切り替えのリンクを設定
// ページ切り替え時に JP | EN 部分のリンクを変更

const onEnter = () => {
  if (document.getElementsByClassName("language")[0] == undefined) {
    return false;
  }

  const linkElm = document
    .getElementsByClassName("js-header")[0]
    .getElementsByClassName("language")[0]
    .getElementsByTagName("a");
  const linkNodes = Array.prototype.slice.call(linkElm, 0);

  const lang = ["", "en"];

  // 言語を除いたbaseのhrefを取得
  const langPath = location.pathname.split("/")[1];
  let baseHref = "";
  if (lang.indexOf(langPath) >= 0) {
    // 英→日 トピック検索ページの場合は検索結果解除
    if (location.pathname.split("/")[2] == "topics_category") {
      if (location.pathname.split("/")[4] == "page") {
        var page_num = location.pathname.split("/")[5];
        baseHref = location.pathname.replace("/page/" + page_num, "");
      }
      baseHref = location.pathname.replace("/" + langPath, "");
    } else {
      baseHref = location.pathname.replace("/" + langPath, "");
    }
  } else {
    // 日→英 トピック検索ページの場合は検索結果解除
    if (location.pathname.split("/")[1] == "topics_category") {
      if (location.pathname.split("/")[3] == "page") {
        var page_num = location.pathname.split("/")[4];
        baseHref = location.pathname.replace("/page/" + page_num, "");
      } else {
        baseHref = location.pathname;
      }
    } else {
      baseHref = location.pathname;
    }
  }

  // href書き換え
  linkNodes.forEach((elm, index) => {
    let href = "";
    if (lang[index]) {
      href = "/" + lang[index] + baseHref;
    } else {
      if (baseHref == "") baseHref = "/";
      href = baseHref;
    }
    elm.href = href;
  });
};

const onLeave = () => {
  // イベント削除
};

export { onEnter, onLeave };
