import $ from "jquery";

const onEnter = () => {
  //チェックボックス
  //読み込み時
  $(".js-checkBox:checked").parent().addClass("is-checked");

  //クリック
  $(".js-checkBox").on("change",function(){
    $(this).parent().toggleClass("is-checked");
  });
};

const onLeave = () => {
  // イベント削除
};

export { onEnter, onLeave };
